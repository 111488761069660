
























































import type { Picture } from '@/inc/types'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { throttle } from 'throttle-debounce'
import { push } from '@/inc/utils'

import Carousel from '@/components/Carousel.vue'

interface Timeline {
  title: string
  items: {
    title: string
    year: string
    text: string
    picture: Picture
  }[]
}

export default defineComponent({
  name: 'FlexibleTimeline',
  components: {
    Carousel,
  },
  props: {
    content: {
      type: Object as PropType<Timeline>,
      required: true,
    },
  },

  setup() {
    const carousel = ref<InstanceType<typeof Carousel> | null>(null)
    const progress = ref(0)
    let scrollerEl: HTMLElement | undefined
    let scrollerWidth = 0

    const setValues = () => {
      if (!scrollerEl) {
        return
      }

      const { scrollWidth, clientWidth } = scrollerEl

      scrollerWidth = scrollWidth - clientWidth
    }

    // Track slider progress, throttled to 1sec
    const trackProgress = throttle(1000, () => {
      push({
        event: 'slider',
        progress: Math.round(progress.value),
      })
    })

    const onResize = throttle(500, () => setValues())

    // Update progress element by watching the scroller element
    const onTimelineScroll = throttle(200, () => {
      if (!scrollerEl) {
        return
      }

      const { scrollLeft } = scrollerEl

      progress.value = (scrollLeft / scrollerWidth) * 100
      trackProgress()
    })

    onMounted(() => {
      scrollerEl = carousel.value?.scrollerRef?.$el as HTMLElement | undefined
      scrollerEl?.addEventListener('scroll', onTimelineScroll)
      setValues()
    })

    onUnmounted(() => {
      scrollerEl?.removeEventListener('scroll', onTimelineScroll)
    })

    return { carousel, progress, onResize }
  },
})
